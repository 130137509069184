import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SliceZone from "../components/SliceZone";

export const AboutUsPage = ({ data }) => {
  if (!data) return null;
  const header = data.prismicHeaderitem || {};
  const footer = data.prismicFooteritem || {};
  const { lang, type, url } = header || {};
  const alternateLanguages = header.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const aboutus = data.prismicAboutus || {};

  return (
    <Layout
      activeDocMeta={activeDoc}
      body={header}
      bodyFooter={footer}
      fullPage={false}
    >
      <SliceZone slices={aboutus.data.body} />
    </Layout>
  );
};

export const query = graphql`
  query AboutUsQuery($lang: String) {
    prismicFooteritem(lang: { eq: $lang }) {
      url
      type
      lang
      alternate_languages {
        lang
        type
      }
      data {
        about {
          text
        }
        allrights {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        item7 {
          text
        }
        privacy {
          text
        }
        terms {
          text
        }
        clientinformation {
          text
        }
        impressum {
          text
        }
      }
    }
    prismicHeaderitem(lang: { eq: $lang }) {
      lang
      type
      url
      alternate_languages {
        lang
        type
      }
      data {
        button {
          text
        }
        item1 {
          text
        }
        item2 {
          text
        }
        item3 {
          text
        }
        aboutus {
          aboutus_name {
            text
          }
        }
        item4 {
          text
        }
        item5 {
          text
        }
        item6 {
          text
        }
        solutions {
          solution_name {
            text
          }
        }
        cookie_consent {
          raw
        }
      }
    }
    prismicAboutus(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicAboutusBodyHeroSection {
            id
            slice_type
            primary {
              title {
                text
              }
              subtitle {
                text
              }
              button {
                text
              }
              button_link {
                url
              }
              background_image {
                url
              }
              pulse_animation
            }
          }
          ... on PrismicAboutusBodyTextureSection {
            id
            slice_type
            primary {
              title {
                text
              }
              emailtext {
                text
              }
              buttontext {
                text
              }
            }
          }
          ... on PrismicAboutusBodyUberuns1Section {
            id
            slice_type
            primary {
              section_title {
                text
              }
              section_number {
                text
              }
              section_name {
                text
              }
              section_description {
                text
              }
            }
          }
          ... on PrismicAboutusBodyUberuns2Section {
            id
            slice_type
            primary {
              title1 {
                text
              }
              text1 {
                raw
              }
              image1 {
                url
              }
              buttontext1 {
                text
              }
              isreversed
            }
          }
          ... on PrismicAboutusBodyUnserzielSection {
            id
            slice_type
            primary {
              title2 {
                text
              }
              title1 {
                text
              }
              text2 {
                raw
              }
              text1 {
                raw
              }
              section_number {
                text
              }
              section_name {
                text
              }
              button_text {
                text
              }
            }
          }
          ... on PrismicAboutusBodyUnserteamSection {
            id
            slice_type
            primary {
              section_title {
                text
              }
              section_text {
                text
              }
              section_number {
                text
              }
              section_name {
                text
              }
            }
            items {
              member_position {
                text
              }
              member_name {
                text
              }
              member_image {
                url
              }
              member_text {
                raw
              }
            }
          }
          ... on PrismicAboutusBodyOurhistorySection {
            id
            slice_type
            primary {
              section_number {
                text
              }
              section_name {
                text
              }
            }
            items {
              item_title_years {
                text
              }
              item_text {
                raw
              }
              item_button_text {
                text
              }
              item_button_link {
                text
              }
            }
          }
        }
      }
      type
      url
      lang
    }
  }
`;

export default AboutUsPage;
